import { useEffect } from 'react';
import { useResources } from 'app/hooks/store/use-resources.hook';

export const DocumentTitle = ({ resource }) => {
  const resources = useResources();

  useEffect(() => {
    if (!resources || !resources.CHRWTRUCKS) {
      return;
    }
    document.title = resource in resources
      ? `${resources[resource]} - ${resources.CHRWTRUCKS}`
      : resources.CHRWTRUCKS;
  }, [resource]);

  return (
    <></>
  );
};
